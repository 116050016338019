import {Link,  useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie";
import { useContext } from 'react';
import { UserContext, UserContextProps } from '../UserContext';
import { config } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

const URL = config.url;

const Navbar: React.FC = () => {
    const { user, setUser } = useContext<UserContextProps>(UserContext);
    const navigate = useNavigate();

    const logout = async () => {
        console.log('Logout button clicked');
        try {
            const response = await fetch(`${URL}/users/logout`, {
                method: 'POST',
                credentials: 'include',
            });
        
            if (response.ok) {
                const cookies = new Cookies();
                cookies.remove("accessToken", { path: "/", domain: "localhost", secure: true });
                cookies.remove("refreshToken", { path: "/", domain: "localhost", secure: true });
                sessionStorage.clear();
                setUser(null);
                console.log('Logout successful');
                navigate('/')
            } else {
                console.error('Logout failed');
            }
            } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <div className="navbar">
            {user  ? (
            <div className="navbar logged-in">
                <div className="nav-left">
                    <div className='nav-logo'>
                        Ramen Crazy
                    </div>
                    <div className="nav-search">
                        <form action="">
                            <div className="search-form">
                                <input type="text" className="search-input" />
                                <input type="submit" value="Search" className="search-button primary-button"/>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="navitems"> 
                    <div className="nav-item">
                        <Link to="/following-feed" className="item">Home</Link>
                    </div>
                    <div className="nav-item">
                        <Link to="/ramen" className="item">Ramens</Link>
                    </div>
                    <div className="nav-item">
                        <Link to="/ingredients" className="item">Ingredients</Link>
                    </div>
                    <div className="user-navitem">
                        <div>
                            <FontAwesomeIcon icon={faUser} className="user-icon"/>
                            {user.userName}
                        </div>
                        <div className='nav-dropdown'>
                            <div className="dropdown-item">
                                <Link to={`/user/show/${user?.userId}`} className="item-in-dropdown">Profile</Link>
                            </div>
                            <div className="dropdown-item">
                                <Link to="/new-ramen" className="item">Add a ramen</Link>
                            </div>
                            <div className="dropdown-item">
                                <Link to="/new-ingredient" className="item">Add ingredients</Link>
                            </div>
                            <div className="dropdown-item">
                                <Link to="/users" className="item">Users</Link>
                            </div>
                            <div className="dropdown-item">
                                <Link to={`/user/settings/${user?.userId}`} className="item-in-dropdown">Settings</Link>
                            </div>
                            <div className="dropdown-item"> 
                                <a href='/' onClick={() => logout()} className="item-in-dropdown">Logout</a>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="navitems nav-home">
                <div className="nav-item">
                    <Link to="/" className="item">Home</Link>
                </div>
                <div className="nav-item">
                    <Link to="/ramen" className="item">Ramens</Link>
                </div>
                <div className="nav-item">
                    <Link to="/login" className="item">Login</Link>
                </div>
                <div className="nav-item nav-cta">
                    <Link to="/signup" className="item">Signup</Link>
                </div>
            </div>
        )}
        </div>
    );
};

export default Navbar;