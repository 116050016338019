import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { config } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEye, faTrash, faBookmark } from '@fortawesome/free-solid-svg-icons'

const URL = config.url;

const RamenCard: React.FC= () => {
    const [ramens, setRamen] = useState<any[]>([]);
    const [ramenUser, setRamenUser] = useState<any[]>([]);
    const [isFavourite, setFavourite] = useState(false);
    const [favoriteRamenIds, setFavoriteRamenIds] =  useState<string[]>([]);
    const userContext = useContext(UserContext);
    const user = userContext?.user;

    

    const navigate = useNavigate();

    const isRamenOwner = (ramenUser: string) => {
        return user?.userId === ramenUser;
    };
    
    useEffect(() => {
        fetch(`${URL}/ramens/ramen`)
            .then((response) => response.json())
            .then((data) => {
                setRamen(data);
                console.log(data)
            })
            .catch((err) => {
                console.log(err.message);
            });
            fetchFavourites();
            fetchUser('');
        }, []);

    const deleteRamen = async (id: string, public_id: string, user_id: string) => {
        await fetch(`${URL}/ramens/ramen/delete/${id}/${public_id}/user/${user_id}`, {
            method: 'DELETE',
            credentials: 'include'
            }).then((response) => {            
            if (response.status === 200) {
                console.log("Ramen deleted");
                setRamen(prevRamen => prevRamen.filter(ramen => ramen._id !== id));
                } else {
                    console.log("Ramen not deleted");
                }
            });
        };

    const viewRamen = async (id:string) => {
        navigate(`/ramen/show/${id}`);
    };

    const updateRamen = (id:string) => {
        navigate(`/ramen/update/${id}`);
    };

    const fetchFavourites = async () => {
        if (user) {
            fetch(`${URL}/users/user/${user.userId}/favourites/bookmarks`,  {
                method:'GET',
                credentials: 'include'
            })
                .then((response) => response.json())
                .then((favourites) => {
                    const favoriteIds = favourites.map((favorite: any) => favorite.ramen_id);
                    setFavoriteRamenIds(favoriteIds);
                    })
                .catch((err) => {
                    console.error(err);
                    console.log("failure");
                });
        }
    };

    const favouriteRamen = async (ramen_id: string, user_id: string) => {
        await fetch(`${URL}/favourites/favourite/ramen/${ramen_id}/user/${user_id}`, {
            method:'POST',
            credentials: 'include'
        }).then((response) => {            
            if (response.status === 200) {
                console.log("Ramen added to favourites");
                setFavourite(true);
                setFavoriteRamenIds((prevIds) =>
                        prevIds.includes(ramen_id)
                            ? prevIds.filter((id) => id !== ramen_id)
                            : [...prevIds, ramen_id]
                    );
                } else {
                    console.log("Ramen not added to favourites");
                    setFavourite(false);
                }
            });
    };

    const fetchUser = async (user_id: string) => {
        if (user) {
        fetch(`(${URL}/user/show/${user.id}`,  {
            method: 'GET',
            credentials: 'include'
        })
        .then((response) => response.json())
        .then((user) => {
            const userData = user.map((user: any) => user.name, user.imageUrl, user.user_id);
            setRamenUser(userData);
            console.log(userData);
            })
        .catch((err) => {
            console.error(err);
            console.log("failure");
        });
    }
    };

    return (
        <div className="">
            <div className="search">   
                <form action="">
                    <div className="search-form">
                        <input type="text" className="search-input" />
                        <input type="submit" value="Search" className="search-button primary-button"/>
                    </div>
                </form>
            </div>
            <div className="card-area">
                {ramens.map((ramen) => {
                    const owner = isRamenOwner(ramen.user);
                return (
                    <div id={ramen._id} key={ramen._id} className="ramen-card" >
                        <div className="card-image-container">
                            <img src={ramen.imageUrl} alt="" style={{width: 400}} />
                        </div>
                        <div className="card-text-area">
                            <h4>{ramen.title}</h4>
                            <h2>{ramen.ingredients}</h2>
                                {ramenUser.map((ramenUser) => {
                                    return (
                                        <div>
                                            <img src={ramenUser.imageUrl} alt="" />
                                            <p>{ramenUser.name}</p>
                                        </div>
                                    )
                                })}
                        {user ? (
                            <div className="card-button-area">
                                <div className="show-button button" onClick={() => viewRamen(ramen._id)} >
                                    <FontAwesomeIcon icon={faEye} className="eye"/>
                                </div>
                                <div className="favourite-button button" onClick={() => favouriteRamen(ramen._id, user?.userId)} id={ramen.id}>
                                        { favoriteRamenIds.includes(ramen._id) ? (
                                            <FontAwesomeIcon icon={faBookmark}  className="favourite-icon"/>
                                        ) : (
                                            <FontAwesomeIcon icon={faBookmark}  className=""/>
                                        )}
                                </div>
                                { owner ? (
                                    <div className="delete-update-favourite-container">   
                                        <div className="update-button button" onClick={() => updateRamen(ramen._id)} >
                                            <FontAwesomeIcon icon={faPenToSquare} className="update"/>
                                        </div>
                                        <div className="delete-button button" onClick={() => deleteRamen(ramen._id, ramen.public_id, ramen.user)} id={ramen.id}>
                                            <FontAwesomeIcon icon={faTrash} className="delete"/>
                                        </div>
                                    </div>
                                    ) : (
                                    <div>
                                    </div>
                                    )
                                }
                            </div>
                                ) : (
                                    <div className="card-button-area">
                                        <div className="show-button button" onClick={() => viewRamen(ramen._id)} >
                                        <FontAwesomeIcon icon={faEye} className="eye"/>
                                        </div>
                                    </div>
                                )}
                            </div>
                    </div>
                    );
                })}
            </div>
        </div>
    );
};

export default RamenCard;