import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { config } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleLeft, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'

const URL = config.url;

const ShowIngredient: React.FC = () => {
    const [ingredient, setIngredient] = useState<any>({
        ingredient: '',
        calories: '',
        imageUrl: '',
        public_id: '',
        });
    const userContext = useContext(UserContext);
    const user = userContext?.user;
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const id = params.id;

        fetch(`${URL}/ingredients/ingredient/show/${id}`, {
            method: 'GET',
            credentials: "include",
            }).then((response) => response.json())
            .then((data) => {
                setIngredient(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
        },
        [params.id]);

        const deleteIngredient = async (id: string, public_id: string, user_id: string) => {
            await fetch(`${URL}/ingredients/ingredient/delete/${id}/${public_id}/user/${user_id}`, {
                method: 'DELETE',
                credentials: 'include'
                }).then((response) => {            
                if (response.status === 200) {
                    console.log("Ingredient deleted");
                    navigate('/ingredients');
                    } else {
                        console.log("Ramen not deleted");
                    }
                });
            };

            const allIngredients = () => {
                navigate('/ingredients');
            }

            const updateIngredient = (id:string) => {
                navigate(`/ingredient/update/${id}`);
            };

            const isIngredientOwner = (ingredientUser: string) => {
                return user?.userId === ingredientUser;
            };

            const owner = isIngredientOwner(ingredient.user);

    return (
        <div className="show-ramen-container fade-page">
            <div className="show-ramen">
                <div className="flex space-around" >
                    <div className="show-page-img-ing">   
                        <div className="show-image-container">  
                            <img src={ingredient.imageUrl} style={{width: 400}} alt="" />
                        </div>
                    </div>
                    <div className="show-page-description">
                    <h1>{ingredient.ingredient}</h1>
                        <div dangerouslySetInnerHTML={{ __html: ingredient.calories}} />
                        {user ? (
                        <div className="card-button-area-show flex">
                            <div className="show-button button" onClick={() => allIngredients()} ><FontAwesomeIcon icon={faCircleLeft} className="back"/> Back to list</div>
                            { owner ? (
                                    <div className="delete-update-container">   
                                        <div className="update-button button" onClick={() => updateIngredient(ingredient._id)} >
                                            <FontAwesomeIcon icon={faPenToSquare} className="update"/>Update
                                        </div>
                                        <div className="delete-button button" onClick={() =>  deleteIngredient(ingredient._id, ingredient.public_id, ingredient.user)} id={ingredient.id}>
                                            <FontAwesomeIcon icon={faTrash} className="delete"/>Delete
                                        </div>
                                    </div>
                                    ) : (
                                    <div>
                                    </div>
                                    )
                                }
                        </div>
                        ) : (
                        <div className="card-button-area-show">
                            <div className="show-button button" onClick={() => allIngredients()} ><FontAwesomeIcon icon={faCircleLeft} className="ramen-bowl"/> Back to list</div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShowIngredient;