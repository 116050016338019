import { ChangeEvent, FormEvent, useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { config } from '../config/config';
import { UserContext } from '../UserContext';
import 'react-quill/dist/quill.snow.css';
import Spinner from '../assets/Spinner.gif';

interface Ingredient {
    ingredient: string;
    calories: string; 
    imageUrl: string;  
    publicId: string;
    user: string;
}

const URL = config.url;

const AddIngredient: React.FC<Ingredient> = () => {
    const [ingredient, setIngredient ] = useState('');
    const [calories, setCalories ] = useState('');    
    const [imageUrl, setImageUrl] = useState('');
    const [publicId, setPublicId] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const userContext = useContext(UserContext);
    const user = userContext?.user;
    const navigate = useNavigate();

    const cloudinaryUsername = process.env.REACT_APP_CLOUDINARY_USERNAME;
    const cloudinaryPreset = process.env.REACT_APP_CLOUDINARY_PRESET;

    const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudinaryUsername}/image/upload`;

    const uploadImage = async (files: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = files.target.files?.[0];
    
        if (selectedFile) {
            setIsUploading(true);
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("upload_preset", `${cloudinaryPreset}`);
        
            try {
            const response = await fetch(uploadUrl, {
                method: "POST",
                body: formData,
            });
        
            if (response.ok) {
                const data = await response.json();
                setImageUrl(data.secure_url);
                setPublicId(data.public_id);
            } else {
                console.log("Image upload failed");
            }
            } catch (error) {
            console.error("Error uploading image:", error);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const AddIngredients = async ({ingredient, calories, imageUrl, publicId, user }: Ingredient) => { 

        await fetch(`${URL}/ingredients/ingredient/add`, {
        method: 'POST',
        credentials: "include",
        body: JSON.stringify({
            ingredient: ingredient,
            calories: calories,
            imageUrl: imageUrl,
            publicId: publicId,
            user: user
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
        })
        .then((response) => { 
            console.log(response.json());
        })
        .then(() => {
        setIngredient('');
        setCalories('');
        })
        .catch((err) => {
        console.log("error message", err.message);
    });
    navigate('/ingredients');
};

const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const ingredients: Ingredient = {
        ingredient, 
        calories, 
        imageUrl, 
        publicId,
        user: user?.userId || ''
    }
    AddIngredients(ingredients);
};

const allFieldsEntered = ingredient && calories && imageUrl && publicId;

    return (
    <div className="form-container">
        <div className="form-image-container">
        {imageUrl && <img src={imageUrl} alt="preview" onLoad={() => setIsImageLoaded(true)} />}
        </div>
        <form method="post" onSubmit={handleSubmit} encType="multipart/form-data">
            <label className="labels">
                Ingredient
                <input 
                    type="text" 
                    name="ingredient" 
                    placeholder="ingredient"
                    onChange={e => setIngredient(e.target.value)} />
            </label>
            <label className="labels">
                Calories
                <input 
                    type="text" 
                    name="calories" 
                    placeholder="calories"
                    onChange={e => setCalories(e.target.value)} />
            </label>
            <label className="labels">
                Image
                {isUploading ? (
                    <div className="flex-no-space">  
                    <div className="spinner-form">
                        <input type="file" name="ramen" onChange={uploadImage}/>
                    </div>
                    <div className="spinner-form-container">
                        <img src={Spinner} alt="spinner" className="spinner"/>
                    </div>
                </div>
                ) : (
                    <input type="file" name="ramen" onChange={uploadImage}/>
                )}
            </label>
            <label className="labels hidden">
                imageUrl
                <textarea 
                    name="imageUrl" 
                    value={imageUrl}
                    onChange={e => setImageUrl(e.target.value)} />
            </label>
            <label className="labels hidden">
                publicId
                <textarea 
                    name="publicId" 
                    value={publicId}
                    onChange={e => setPublicId(e.target.value)} />
            </label>
            {isImageLoaded && allFieldsEntered ?(
                        <input
                            type="submit"
                            value="Add ingredient"
                            className="primary-submit-button"
                            disabled={!isImageLoaded}
                        />
                    ) : (
                        <input
                            type="submit"
                            value="Fill in form first"
                            className="primary-submit-button-grey"
                            disabled={!isImageLoaded}
                        />
                    )}
        </form>
    </div>
    )
};

export default AddIngredient;
