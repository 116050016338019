import { useState, useEffect, useContext,  } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext, UserContextProps } from '../UserContext';
import { config } from '../config/config';

const URL = config.url;

const UserSettings: React.FC = () => {
    const { user, setUser } = useContext<UserContextProps>(UserContext);
    const navigate = useNavigate();

    console.log("user:", user?.userId)
        
        const deleteUser = async (id: string, public_id: string) => {
            console.log("delete:",id)
            console.log("delete:",public_id)

            fetch(`${URL}/users/user/delete/${id}/${public_id}`, {
            method: 'DELETE',
            credentials: "include",
            }).then((response) => {            
                if (response.status === 200) {
                    setUser(user);
                    console.log("User deleted");
                    } else {
                        return;
                    }
                });
                navigate('/home');
            };

        const updateUser = (id:string) => {
                navigate(`/user/update/${id}`);
            };

    return (
        <div className="show-user-container">
            <div className="">
                    <div className="" >
                        <div className="">
                            <h1>Update your user account here</h1>
                            <p>Change your username, email address or password</p>
                            <div className="update-button" onClick={() => updateUser(user?.userId)} >Update</div>
                        </div>
                        <div>
                            <h1>Delete your user account here</h1>
                            <p>Your user data, ramens and ingredients will be deleted from our database permanently.</p>
                            <div className="delete-button" onClick={() => deleteUser(user?.userId , user?.public_id)} id={user?.userId}>Delete</div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default UserSettings;