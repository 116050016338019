import { config } from '../config/config';
import { SecondaryButton } from './buttons';
import { useNavigate } from 'react-router-dom';

interface User {
    username: string;
    imageUrl: string;
    _id: string;
}

const URL = config.url;

const UserCard: React.FC<User> = ({ username, imageUrl, _id }) => {
    const navigate = useNavigate();

    const followUser = async (_id: string) => { 
        console.log(_id)
        try {
            const response = await fetch(`${URL}/follows/follow/${_id}`, {
                method: 'POST',
                credentials: 'include'
                }
            );
        
            const data = await response.json();
            console.log(data.message); // Log the response from the server
        } catch (error) {
            console.error('Error:', error);
        }
        };

    const navigateUser = async (_id: string) => {
        navigate(`/user/show/${_id}`)

    }

    return (
        <div className="primary-card flex space-between align-vert-center">
            <div className="flex align-vert-center">
                <img src={imageUrl} alt="user-profile" className="user-profile-pic-list" />
                <p>{username}</p>
            </div>
            <div className="flex">
                <SecondaryButton 
                    buttonText="Follow"
                    onClick={() =>followUser(_id)}
                />
                <SecondaryButton 
                    buttonText="See Profile"
                    onClick={() => navigateUser(_id)}
                />
            </div>
        </div>
    );
};

export default UserCard;