import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { config } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'

const URL = config.url;

interface Ingredient {
    id: string;
    ingredient: string;
    calories: number;
    imageUrl: string;
    public_id: string;
    user: string;
}

const IngredientList: React.FC<Ingredient> = () => {
    const [ingredients, setIngredient] = useState<any[]>([]);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const isIngredientOwner = (ramenUser: string) => {
        return user?.userId === ramenUser;
    };
    
    useEffect(() => {
        fetch(`${URL}/ingredients/ingredients`, {
            method: 'GET',
            credentials: 'include',
            })
            .then((response) => response.json())
            .then((data) => {
                setIngredient(data);
            })
            .catch((err) => {
                console.log(err.message);
            });  
        }, []);

    const deleteIngredient = async (id:string, public_id: string, user_id:string, user:string) => {
        await fetch(`${URL}/ingredient/delete/${id}/${public_id}/user/${user_id}`, {
            method: 'DELETE',
            credentials: 'include',
            }).then((response) => {            
            if (response.status === 200) {
                    console.log("Ingredient deleted");
                    setIngredient(prevIngredient => prevIngredient.filter(ingredient => ingredient._id !== id));
                } else {
                    console.log("Ingredient not deleted");
                }
            });
        };

    const viewIngredient = async (id:string) => {
        console.log("this is id", id);
        navigate(`/ingredient/show/${id}`);
    };

    const updateIngredient = (id:string) => {
        navigate(`/ingredient/update/${id}`);
    };

    return (
        <div className="">
            <div className="card-area">
                {ingredients.map((ingredient) => {
                    const owner = isIngredientOwner(ingredient.user);

                return (
                    <div key={ingredient._id} id={ingredient._id} className="ramen-card" >
                        <div className="card-image-container">
                            <img src={ingredient.imageUrl} alt="" style={{width: 400}} />
                        </div>
                        <div className="card-text-area">
                            <h4>{ingredient.ingredient}</h4>
                            <h2>{ingredient.calories}</h2>
                        {user ? (
                            <div className="card-button-area">
                                <div className="show-button button" onClick={() => viewIngredient(ingredient._id)} >
                                    <FontAwesomeIcon icon={faEye} className="eye"/>
                                </div>
                                { owner ? (
                                    <div className="delete-update-container">
                                        <div className="update-button button" onClick={() => updateIngredient(ingredient._id)} >
                                            <FontAwesomeIcon icon={faPenToSquare} className="update"/>
                                        </div>
                                        <div className="delete-button button" onClick={() => deleteIngredient(ingredient._id, ingredient.public_id, sessionStorage.getItem('id')!, ingredient.user)} id={ingredient.id}>
                                            <FontAwesomeIcon icon={faTrash} className="delete"/>
                                        </div>
                                    </div>
                                ):(
                                    <div>
                                    </div>
                                )}
                            </div>
                                ) : (
                                    <div className="card-button-area">
                                        <div className="show-button button" onClick={() => viewIngredient(ingredient._id)} >
                                        <FontAwesomeIcon icon={faEye} className="eye"/>
                                        </div>
                                    </div>
                                )}
                            </div>
                    </div>
                    );
                })}
            </div>
        </div>
    );
};

export default IngredientList;