import AddRamenForm from "../components/addRamenForm";

const NewRamen = () => {
    return (
        <div className="">
            <div className="fade-page">
                <div className="page-title">
                    <h1>Add ramen</h1>
                </div>
                <AddRamenForm />
            </div>
        </div>
    );
}

export default NewRamen;