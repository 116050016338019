import UpdateUserForm from "../components/updateUserForm";

const UpdateUser = () => {

    return (
        <div className="">
            <div className="">
                <UpdateUserForm />
            </div>
        </div>
    );
}

export default UpdateUser;