import { useState, useEffect, useContext,  } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../config/config';
import { UserContext, UserContextProps } from '../UserContext';
import UserRamens from "../components/userRamens";
import UserIngredients from "../components/userIngredients";
import UserFavourites from "../components/userFavourites";
import { TertiaryButton } from "../components/buttons";


const URL = config.url;

interface User {
    id: string;
    _id: string;
    name: string;
    surname: string;
    email: string;
    password: string;
    imageUrl: string;
    header_image_url:string;
    header_public_id:string;
    publicId: string;
    public_id: string;
}

const ShowUser: React.FC<User> = () => {
    const navigate = useNavigate();
    const [selectedMenuItem, setSelectedMenuItem] = useState('Your Ramens');
    const [user, setUser] = useState<User>({ 
        id:'', 
        name: '', 
        surname: '', 
        email: '', 
        password: '', 
        imageUrl: '', 
        header_image_url: '',
        header_public_id: '',
        publicId: '', 
        _id:'', 
        public_id:'' 
    });
    const [followingCount, setFollowingCount] = useState(0);
    const [followersCount, setFollowersCount] = useState(0);
    const params = useParams();

    const handleMenuItemClick = (item: any) => {
        setSelectedMenuItem(item);
    };

    useEffect(() => {
        getUser();
        fetchFollowersCount();
        fetchFollowingCount();
        },
    []);

    const id = params.id;

    const getUser = () => {
        fetch(`${URL}/users/user/show/${id}`, {
            method: 'GET',
            credentials: "include",
            })
            .then((response) => response.json())
            .then((data) => {
                setUser(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const followUser = async (_id: string) => { 
        console.log(_id)
        try {
            const response = await fetch(`${URL}/follows/follow/${_id}`, {
                method: 'POST',
                credentials: 'include'
                }
            );
        
            const data = await response.json();
            console.log(data.message);
        } catch (error) {
            console.error('Error:', error);
        }
        };

    const fetchFollowersCount = async () => {
        try {
            // Call the function to get the count of followers
        //   const count = await getFollowersCount(user._id);
        //   setFollowersCount(count);
        } catch (error) {
            console.error('Error fetching followers count:', error);
        }
    };

    const fetchFollowingCount = async () => {
        try {
            // Call the function to get the count of followers
        //   const count = await getFollowersCount(user._id);
        //   setFollowersCount(count);
        } catch (error) {
            console.error('Error fetching followers count:', error);
        }
    };

    const navigateFollowers = async (_id: string) => {
        navigate(`/user/followers/${_id}`)

    }

    const navigateFollowing = async (_id: string) => {
        navigate(`/user/following/${_id}`)

    }

    return (
        <div className="show-user-container">
            <div className="">
                <div className="profile-header">
                    <div className="profile-header-image">
                        <img src={user.header_image_url} alt="header" className="header-image" />
                    </div> 
                    <div className="show-user-image-container">  
                        <img src={user.imageUrl} alt="" className="show-user-image"/>
                    </div>
                    <div className="profile-user-name">
                        <h1>{user.name} {user.surname}</h1>
                        <div className="profile-follow-buttons-area">
                        <TertiaryButton
                                buttonText="Follow"
                                onClick={() => followUser(user._id)}
                            />
                            <TertiaryButton
                                buttonText={`${followingCount} Following`}
                                onClick={() => navigateFollowing(user._id)}
                            />
                            <TertiaryButton
                                buttonText={`${followersCount} Followers`}
                                onClick={() => navigateFollowers(user._id)}
                            />
                            
                        </div>
                    </div>
                    <div className="profile-sections">
                        <ul className="profile-menu">
                            <li className="profile-menu-button" onClick={() => handleMenuItemClick('Your Ramens')}>Your Ramens</li>
                            <li className="profile-menu-button" onClick={() => handleMenuItemClick('Your Ingredients')}>Your Ingredients</li>
                            <li className="profile-menu-button" onClick={() => handleMenuItemClick('Your Favourites')}>Your Favourites</li>
                        </ul>
                    </div>
                </div>
                <div className="profile-content-container">  
                    {selectedMenuItem === 'Your Ramens' && <UserRamens />}
                    {selectedMenuItem === 'Your Ingredients' && <UserIngredients />}
                    {selectedMenuItem === 'Your Favourites' && <UserFavourites />}
                </div>
                
            </div>
        </div>
    );
}

export default ShowUser;