import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faEye, faTrash, faBookmark } from '@fortawesome/free-solid-svg-icons';

interface Ramen {
    _id: string;
    imageUrl: string;
    title: string;
    ingredients: string;
    user: string;
    public_id: string;
    id: string;
}

interface RamenUser {
    imageUrl: string;
    name: string;
}

interface CardProps {
    ramens: Ramen[];
    ramenUser: RamenUser[];
    favoriteRamenIds: string[];
    user: any;
    isRamenOwner: (ramenUser: string) => boolean;
    viewRamen: (ramenId: string) => void;
    favouriteRamen: (ramenId: string, userId: string | undefined) => void;
    updateRamen: (ramenId: string) => void;
    deleteRamen: (id: string, public_id: string, user_id: string) => void;
}

const Card: React.FC<CardProps> = (props: {
        ramens: any,
        ramenUser: any,
        favoriteRamenIds: any,
        user: any,
        isRamenOwner: any,
        viewRamen: any,
        favouriteRamen: any,
        updateRamen: any,
        deleteRamen: any,
    }) => {
        const { 
            ramens, 
            ramenUser, 
            favoriteRamenIds, 
            user, 
            isRamenOwner, 
            viewRamen, 
            favouriteRamen, 
            updateRamen, 
            deleteRamen  
        } = props;

    return (
        <div className="">
            <div className="">
                <div className="">
                    <div className="card-area">
                        {ramens.map((ramen: any) => {
                            const owner = isRamenOwner(ramen.user);
                        return (
                            <div id={ramen._id} key={ramen._id} className="ramen-card" >
                                <div className="card-image-container">
                                    <img src={ramen.imageUrl} alt="" style={{width: 400}} />
                                </div>
                                <div className="card-text-area">
                                    <h4>{ramen.title}</h4>
                                    <h2>{ramen.ingredients}</h2>
                                        {ramenUser.map((ramenUser: any) => {
                                            return (
                                                <div>
                                                    <img src={ramenUser.imageUrl} alt="" />
                                                    <p>{ramenUser.name}</p>
                                                </div>
                                            )
                                        })}
                                {user ? (
                                    <div className="card-button-area">
                                        <div className="show-button button" onClick={() => viewRamen(ramen._id)} >
                                            <FontAwesomeIcon icon={faEye} className="eye"/>
                                        </div>
                                        <div className="favourite-button button" onClick={() => favouriteRamen(ramen._id, user?.userId)} id={ramen.id}>
                                                { favoriteRamenIds.includes(ramen._id) ? (
                                                    <FontAwesomeIcon icon={faBookmark}  className="favourite-icon"/>
                                                ) : (
                                                    <FontAwesomeIcon icon={faBookmark}  className=""/>
                                                )}
                                        </div>
                                        { owner ? (
                                            <div className="delete-update-favourite-container">   
                                                <div className="update-button button" onClick={() => updateRamen(ramen._id)} >
                                                    <FontAwesomeIcon icon={faPenToSquare} className="update"/>
                                                </div>
                                                <div className="delete-button button" onClick={() => deleteRamen(ramen._id, ramen.public_id, ramen.user)} id={ramen.id}>
                                                    <FontAwesomeIcon icon={faTrash} className="delete"/>
                                                </div>
                                            </div>
                                            ) : (
                                            <div>
                                            </div>
                                            )
                                        }
                                    </div>
                                        ) : (
                                            <div className="card-button-area">
                                                <div className="show-button button" onClick={() => viewRamen(ramen._id)} >
                                                <FontAwesomeIcon icon={faEye} className="eye"/>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;