import Card from '../components/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlFood } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';



const FollowingFeed: React.FC = () => {
    const [users, setUsers] = useState([]);
    const [ramens, setRamens] = useState([]);
    
    useEffect(() => {
            fetchFollowedUsers();
        }, []);

    const fetchFollowedUsers = () => {
        fetch(`${URL}/ramens/ramen`)
        .then((response) => response.json())
        .then((data) => {
            setUsers(data);
        })
        .catch((err) => {
            console.log(err.message);
        });
    }

    return (
        <div className="ramen-list-body fade-page">
            <div className="">
                <div className="page-title">
                    <h1 style={{
                                color: "#ED3D1E"
                                }}>あなたのために</h1>
                    <hr style={{
                            background: "#272624",
                            border: "1px solid",
                            height:"1px"
                        }}/>
                    <h1>For You <FontAwesomeIcon icon={faBowlFood} className="ramen-bowl"/></h1>
                </div>
                <Card ramens={[]} ramenUser={[]} favoriteRamenIds={[]} user={undefined} isRamenOwner={function (ramenUser: string): boolean {
                    throw new Error('Function not implemented.');
                } } viewRamen={function (ramenId: string): void {
                    throw new Error('Function not implemented.');
                } } favouriteRamen={function (ramenId: string, userId: string | undefined): void {
                    throw new Error('Function not implemented.');
                } } updateRamen={function (ramenId: string): void {
                    throw new Error('Function not implemented.');
                } } deleteRamen={function (id: string, public_id: string, user_id: string): void {
                    throw new Error('Function not implemented.');
                } } />
            </div>
        </div>
    );
}

export default FollowingFeed;