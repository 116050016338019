import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { config } from '../config/config';
import Spinner from '../assets/Spinner.gif';

const URL = config.url;

interface User {
    name: string;
    surname: string;
    username:  string;
    email: string;
    password: string;
    imageUrl: string;
    header_image_url: string;
    publicId: string;
    header_public_id:string;
    }

const AddUser: React.FC = () => {
    const [name, setName ] = useState('');
    const [surname, setSurname ] = useState('');
    const [username, setUsername ] = useState('');
    const [email, setEmail ] = useState('');
    const [password, setPassword] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [header_image_url, setHeaderImageUrl] = useState('');
    const [publicId, setPublicId] = useState('');
    const [header_public_id, setHeaderPublicId] = useState('');
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isEmailValidState, setEmailValid] = useState(false);
    const [isPasswordValidState, setPasswordValid] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadingBanner, setIsUploadingBanner] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [responseFalse, setResponseFalse] = useState(false);

    const navigate = useNavigate();

    const cloudinaryUsername = process.env.REACT_APP_CLOUDINARY_USERNAME;
    const cloudinaryPreset = process.env.REACT_APP_CLOUDINARY_PRESET;

    const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudinaryUsername}/image/upload`;

    const uploadImage = async (files: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = files.target.files?.[0];
    
        if (selectedFile) {
            setIsUploading(true);
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("upload_preset", `${cloudinaryPreset}`);
        
            try {
            const response = await fetch(uploadUrl, {
                method: "POST",
                body: formData,
            });
        
            if (response.ok) {
                const data = await response.json();
                setImageUrl(data.secure_url);
                setPublicId(data.public_id);
            } else {
                console.log("Image upload failed");
            }
            } catch (error) {
            console.error("Error uploading image:", error);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const uploadBannerImage = async (files: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = files.target.files?.[0];
    
        if (selectedFile) {
            setIsUploadingBanner(true);
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("upload_preset", `${cloudinaryPreset}`);
        
            try {
            const response = await fetch(uploadUrl, {
                method: "POST",
                body: formData,
            });
        
            if (response.ok) {
                const data = await response.json();
                setHeaderImageUrl(data.secure_url);
                setHeaderPublicId(data.public_id)

            } else {
                console.log("Image upload failed");
            }
            } catch (error) {
            console.error("Error uploading image:", error);
            } finally {
                setIsUploadingBanner(false);
            }
        }
    };

    const AddUser = async ( {name, surname, username, email, password, imageUrl, header_image_url, publicId, header_public_id}: User) => {
        await fetch(`${URL}/users/signup`, {
        method: 'POST',
        credentials: "include",
        body: JSON.stringify({
            name: name,
            surname: surname,
            username: username,
            email: email,
            password: password,
            imageUrl: imageUrl,
            header_image_url: header_image_url,
            publicId: publicId,
            header_public_id: header_public_id,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
        })
        .then((response) => { 
            console.log(response.json());
        })
        .then(() => {
        setName('');
        setSurname('');
        setUsername('');
        setEmail('');
        setPassword('');
        })
        .catch((err) => {
        console.log(err.message , ":error message");
        setResponseFalse(true);
    });
    navigate('/login');
    };

    const isEmailValid = (email: string) => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        return emailRegex.test(email);
    };
    
    const isPasswordValid = (password: string) => {
        const passwordRegex = /^(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);

        const user: User = {
            name, 
            surname, 
            username,
            email, 
            password, 
            imageUrl,
            header_image_url, 
            publicId,
            header_public_id
        };

        const isEmailValidState = isEmailValid(email);
        const isPasswordValidState = isPasswordValid(password);

        setEmailValid(isEmailValidState);
        setPasswordValid(isPasswordValidState);

        if (isEmailValidState && isPasswordValidState) {
            AddUser(user);
        }
    };

    const allFieldsEntered = name && surname && username && isEmailValidState && isPasswordValidState && imageUrl && publicId && header_image_url && header_public_id;

    return (
    <div className="form-container">
        <div className="signup-form-profile-banner-container">   
            <div className="form-user-banner-image-container">
                {header_image_url && <img className="new-user-banner-image" src={header_image_url} alt="preview" onLoad={() => setIsImageLoaded(true)}/>}
            </div>
            <div className="form-user-image-container">
                {imageUrl && <img className="new-user-image" src={imageUrl} alt="preview" onLoad={() => setIsImageLoaded(true)}/>}
            </div>
        </div>
        <form method="post" onSubmit={handleSubmit} encType="multipart/form-data">
            <label className="labels">
                Name
                <input 
                    type="text" 
                    name="name" 
                    placeholder="name"
                    onChange={e => setName(e.target.value)} />
            </label>
            <label className="labels">
                Surname
                <input 
                    type="text" 
                    name="surname" 
                    placeholder="surname"
                    onChange={e => setSurname(e.target.value)} />
            </label>
            <label className="labels">
                Username
                <input 
                    type="text" 
                    name="username" 
                    placeholder="username"
                    onChange={e => setUsername(e.target.value)} />
            </label>
            <label className="labels">
                Email
                <input
                    type="text" 
                    name="email" 
                    placeholder="email"
                    onChange={e => {
                        const email = e.target.value;
                        setEmail(email);
                        setEmailValid(isEmailValid(email));
                    } }
                    />
                {formSubmitted && !isEmailValidState && (
                    <p className="validation-message">Please enter a valid email address.</p>
                )}
            </label>
            <label className="labels">
                Password (8 Characters with 1 symbol)
                <input 
                    type="text" 
                    name="password" 
                    placeholder="password"
                    onChange={(e) => {
                        const password = e.target.value;
                        setPassword(password);
                        setPasswordValid(isPasswordValid(password));
                    }}
                />
                {formSubmitted && !isPasswordValidState && (
                    <p className="validation-message">
                        Password must be at least 8 characters long and contain at least one symbol.
                    </p>
                )}
            </label>
            <label className="labels">
                * Profile Picture 
                    <br/>- Please allow your picture to load
                    <br/>- File size under 1MB
                {isUploading ? (
                    <div className="flex-no-space">  
                    <div className="spinner-form">
                        <input type="file" name="ramen"className="file-upload-button" onChange={uploadImage}/>
                    </div>
                    <div className="spinner-form-container">
                        <img src={Spinner} alt="spinner" className="spinner"/>
                    </div>
                </div>
                ) : (
                    <input type="file" name="user-image" onChange={uploadImage}/>
                )}
            </label>
            <label className="labels">
                * Banner Image 
                    <br/>- Please allow your picture to load
                    <br/>- File size under 1MB
                {isUploadingBanner ? (
                    <div className="flex-no-space">  
                    <div className="spinner-form">
                        <input type="file" name="ramen" onChange={uploadBannerImage}/>
                    </div>
                    <div className="spinner-form-container">
                        <img src={Spinner} alt="spinner" className="spinner"/>
                    </div>
                </div>
                ) : (
                    <input type="file" name="banner-image" onChange={uploadBannerImage}/>
                )}
            </label>
            <label className="labels hidden">
                imageUrl
                <input
                    type="text" 
                    name="imageUrl" 
                    value={imageUrl}
                    onChange={e => setImageUrl(e.target.value)} />
            </label>
            <label className="labels hidden">
                headerImageUrl
                <input
                    type="text" 
                    name="imageUrl" 
                    value={header_image_url}
                    onChange={e => setHeaderImageUrl(e.target.value)} />
            </label>
            <label className="labels hidden">
                publicId
                <input
                    type="text" 
                    name="publicId" 
                    value={publicId}
                    onChange={e => setPublicId(e.target.value)} />
            </label>
            <label className="labels hidden">
                headerPublicId
                <input
                    type="text" 
                    name="publicId" 
                    value={header_public_id}
                    onChange={e => setHeaderPublicId(e.target.value)} />
            </label>
            {isImageLoaded && allFieldsEntered ?(
                        <input
                            type="submit"
                            value="Signup"
                            className="primary-submit-button"
                            disabled={!isImageLoaded}
                        />
                    ) : (
                        <input
                            type="submit"
                            value="Fill in form first"
                            className="primary-submit-button-grey"
                            disabled={!isImageLoaded}
                        />
                    )}
                <p>
                    Please note that you will be redirected to login
                    <br/>Use the same details to enter
                </p>
        </form>
    </div>
    )
};

export default AddUser;
