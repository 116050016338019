
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlFood } from '@fortawesome/free-solid-svg-icons'
import UserCard from '../components/userCard';
import { useEffect, useState } from 'react';
import { config } from '../config/config';
const URL = config.url;

interface Users {
    username: string;
    imageUrl: string;
    _id: string;
}


const Following= () => {
    const [following, setFollowing] = useState<Users[]>([]);

    useEffect(() => {
        const fetchFollowers = async () => {
            fetch(`${URL}/follows/following`, {
                method: 'GET',
                credentials: 'include'
                })
            .then((response) => response.json())
            .then((data) => {
                setFollowing(data);
                console.log(data)
            })
            .catch((error) =>
            console.log("Error fetching users", error)
            )};
            fetchFollowers();
    }, []);


    return (
        <div className="ramen-list-body fade-page">
            <div className="">
                <div className="page-title">
                    <h1 style={{
                                color: "#ED3D1E"
                                }}>いただきます</h1>
                    <hr style={{
                            background: "#272624",
                            border: "1px solid",
                            height:"1px"
                        }}/>
                    <h1>Following <FontAwesomeIcon icon={faBowlFood} className="ramen-bowl"/></h1>
                </div>
                {following.map((following, index) => (
                    <UserCard key={index} {...following} />
                ))
                }
            </div>
        </div>
    );
}

export default Following;