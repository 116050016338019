import { useNavigate, useParams } from 'react-router-dom';
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { UserContext } from '../UserContext';
import { config } from '../config/config';
import 'react-quill/dist/quill.snow.css';
import Spinner from '../assets/Spinner.gif';

const URL = config.url;

interface Ingredient {
    ingredient: string;
    calories: string; 
    imageUrl: string;  
    publicId: string;
    user: string;
}

const UpdateIngredientForm = () => {
    const [ingredient, setIngredient ] = useState('');
    const [calories, setCalories ] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [publicId, setPublicId] = useState('');
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const userContext = useContext(UserContext);
    const user = userContext?.user;
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const id = params.id;
        fetch(`${URL}/ingredients/ingredient/show/${id}`, {
            method: 'GET',
            credentials: 'include',
            }).then((response) => response.json())
            .then((data) => {
                setIngredient(data.ingredient);
                setCalories(data.calories);
                setImageUrl(data.imageUrl);
                setPublicId(data.public_id);
            })
            .catch((err) => {
                console.log(err.message);
            });
        },
        [params.id]);

        const cloudinaryUsername = process.env.REACT_APP_CLOUDINARY_USERNAME;
        const cloudinaryPreset = process.env.REACT_APP_CLOUDINARY_PRESET;
        const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudinaryUsername}/image/upload`;
        
        const uploadImage = async (files: ChangeEvent<HTMLInputElement>) => {
            const selectedFile = files.target.files?.[0];
        
            if (selectedFile) {
                setIsUploading(true);
                const formData = new FormData();
                formData.append("file", selectedFile);
                formData.append("upload_preset", `${cloudinaryPreset}`);
        
            try {
                const response = await fetch(uploadUrl, {
                method: "POST",
                body: formData,
                });
        
                if (response.ok) {
                    const data = await response.json();
                    setImageUrl(data.secure_url);
                    setPublicId(data.public_id);
                } else {
                    console.log("Image upload failed");
                }
                } catch (error) {
                    console.error("Error uploading image:", error);
                } finally {
                    setIsUploading(false);
                }
            }
        };

    const updateIngredient = async ({ingredient, calories, imageUrl, publicId}: Ingredient) => {
        const id = params.id

        await fetch(`${URL}/ingredients/ingredient/update/${id}`, {
            method: 'PUT',
            credentials: "include",
            body: JSON.stringify({
                ingredient: ingredient,
                calories: calories,
                imageUrl: imageUrl,
                publicId: publicId
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => { 
                response.json();
            })
            .then(() => {
            setIngredient('');
            setCalories('');
            setImageUrl('');
            setPublicId('');
            })
            .catch((err) => {
            console.log(err.message , ":error message");
        });
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        const id = params.id
        const ingredients: Ingredient = { 
            ingredient, 
            calories, 
            imageUrl, 
            publicId, 
            user: user?.userId || ''
        }
        updateIngredient(ingredients);
        navigate(`/ingredient/show/${id}`);
        
    };
    

    return (
        <div className="form-container">
        <div className="form-image-container">
            {imageUrl && <img className="new-ramen-image" src={imageUrl} alt="preview" onLoad={() => setIsImageLoaded(true)} />}
        </div>
        <form method="post" onSubmit={handleSubmit} encType="multipart/form-data">
            <label className="labels">
                Ingredient
                <input 
                    type="text" 
                    name="title" 
                    placeholder={ingredient}
                    onChange={e => setIngredient(e.target.value)} />
            </label>
            <label className="labels">
                Calories
                <input 
                    type="text" 
                    name="ingredients" 
                    placeholder={calories}
                    onChange={e => setCalories(e.target.value)} />
            </label>
            <label className="labels">
                Image
                {isUploading ? (
                    <div className="flex-no-space">  
                    <div className="spinner-form">
                        <input type="file" name="ramen" onChange={uploadImage}/>
                    </div>
                    <div className="spinner-form-container">
                        <img src={Spinner} alt="spinner" className="spinner"/>
                    </div>
                </div>
                ) : (
                    <input type="file" name="ramen" onChange={uploadImage}/>
                )}
            </label>
            <label className="labels hidden">
                imageUrl
                <textarea 
                    name="imageUrl" 
                    value={imageUrl}
                    placeholder={imageUrl}
                    onChange={e => setImageUrl(e.target.value)} />
            </label>
            <label className="labels hidden">
                publicId
                <textarea 
                    name="publicId" 
                    value={publicId}
                    placeholder={publicId}
                    onChange={e => setPublicId(e.target.value)} />
            </label>
            <input type="submit" value="Submit" className="primary-submit-button" />
        </form>
    </div>
    )
};

export default UpdateIngredientForm;
