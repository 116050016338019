import UpdateIngredientForm from "../components/updateIngredientForm";

const UpdateIngredient = () => {



    return (
        <div className="">
            <div className="fade-page">
                <div className="page-title">
                    <h1>Update an Ingredient</h1>
                </div>
                <UpdateIngredientForm />
            </div>
        </div>
    );
}

export default UpdateIngredient;