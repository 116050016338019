import {Link} from 'react-router-dom';

const PrimaryButton = () => {
    return (
        <div>
            <Link to="/ramen" className="primary-button">
                See Ramens
            </Link>
        </div>
    );
};


const SecondaryButton = (props: { buttonText: string; onClick: any }) => {
    const { buttonText, onClick  } = props;

    return (
        <div className="">
            <div className="secondary-button" onClick={onClick}>
                    {buttonText}
            </div>
        </div>
    );
};



const TertiaryButton = (props: { buttonText: string; onClick: any}) => {
    const { buttonText, onClick  } = props;

    return (
        <div className="">
            <div className="tertiary-button" onClick={onClick}>
                    {buttonText}
            </div>
        </div>
    );
};

export  {PrimaryButton, SecondaryButton, TertiaryButton};