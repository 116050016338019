import { useNavigate, useParams } from 'react-router-dom';
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { UserContext, UserContextProps } from '../UserContext';
import { config } from '../config/config';
import Spinner from '../assets/Spinner.gif';

import Cookies from "universal-cookie";

const cookies = new Cookies();

const URL = config.url;

interface User {
    id: string;
    name: string;
    surname: string;
    username:  string;
    email: string;
    imageUrl: string;
    header_image_url: string;
    publicId: string;
    header_public_id:string;
}

const UpdateUserForm: React.FC = () => {
    // const { user, setUser } = useContext<UserContextProps>(UserContext);
    const [name, setName ] = useState('');
    const [surname, setSurname ] = useState('');
    const [username, setUsername ] = useState('');
    const [email, setEmail ] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [header_image_url, setHeaderImageUrl] = useState('');
    const [header_public_id, setHeaderPublicId] = useState('');
    const [isEmailValidState, setEmailValid] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadingBanner, setIsUploadingBanner] = useState(false);
    const [publicId, setPublicId] = useState('');
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const id = params.id;
        fetch(`${URL}/users/user/show/${id}`, {
            method: 'GET',
            credentials: "include",
            }).then((response) => response.json())
            .then((data) => {
                setName(data.name);
                setSurname(data.surname);
                setUsername(data.username);
                setEmail(data.email);
                setImageUrl(data.imageUrl);
                setPublicId(data.public_id);
                setHeaderImageUrl(data.header_image_url);
                setHeaderPublicId(data.header_public_id);
            })
            .catch((err) => {
                console.log(err.message);
            });
        },
        [params.id]);
    
    const cloudinaryUsername = process.env.REACT_APP_CLOUDINARY_USERNAME;
    const cloudinaryPreset = process.env.REACT_APP_CLOUDINARY_PRESET;

    const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudinaryUsername}/image/upload`;

    const uploadImage = async (files: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = files.target.files?.[0];
    
        if (selectedFile) {
            setIsUploading(true);
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("upload_preset", `${cloudinaryPreset}`);
        
            try {
            const response = await fetch(uploadUrl, {
                method: "POST",
                body: formData,
            });
        
            if (response.ok) {
                const data = await response.json();
                setImageUrl(data.secure_url);
                setPublicId(data.public_id);
            } else {
                console.log("Image upload failed");
            }
            } catch (error) {
            console.error("Error uploading image:", error);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const uploadBannerImage = async (files: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = files.target.files?.[0];
    
        if (selectedFile) {
            setIsUploadingBanner(true);
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("upload_preset", `${cloudinaryPreset}`);
        
            try {
            const response = await fetch(uploadUrl, {
                method: "POST",
                body: formData,
            });
        
            if (response.ok) {
                const data = await response.json();
                setHeaderImageUrl(data.secure_url);
                setHeaderPublicId(data.public_id)
                console.log(data.secure_url, data.public_id)

            } else {
                console.log("Image upload failed");
            }
            } catch (error) {
            console.error("Error uploading image:", error);
            } finally {
                setIsUploadingBanner(false);
            }
        }
    };

    const updateUser = async ({id, name, surname, username, email, imageUrl, publicId, header_public_id, header_image_url}: User) => {
        const token = cookies.get("TOKEN");

        await fetch(`${URL}/users/user/update/${id}`, {
            method: 'PUT',
            credentials: "include",
            body: JSON.stringify({
                name: name,
                surname: surname,
                username: username,
                email: email,
                imageUrl: imageUrl,
                header_image_url: header_image_url,
                publicId: publicId,
                header_public_id: header_public_id,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `${token}`,
            },
            })
            .then((response) => { 
                response.json();
            })
            .then(() => {
            setName('');
            setSurname('');
            setEmail('');
            setImageUrl('');
            setPublicId('');
            setHeaderImageUrl('');
            setHeaderPublicId('');
            setUsername('');
            })
            .catch((err) => {
            console.log(err.message , ":error message");
        });
    }

    const isEmailValid = (email: string) => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        return emailRegex.test(email);
    };
    

    const handleSubmit = () => {
        const id = params.id
        
        const user: User = {
            id: params.id as string,
            name, 
            surname, 
            username,
            email,
            imageUrl,
            header_image_url, 
            publicId,
            header_public_id
        };

        updateUser(user);

        const isEmailValidState = isEmailValid(email);
        setFormSubmitted(true);
        setEmailValid(isEmailValidState);

        navigate(`/user/show/${id}`);
    };

    const allFieldsEntered = name && surname && username && isEmailValidState && imageUrl && publicId && header_image_url && header_public_id;
    

    return (
        <div className="form-container">
        <div className="signup-form-profile-banner-container">   
            <div className="form-user-banner-image-container">
                {header_image_url && <img className="new-user-banner-image" src={header_image_url} alt="preview" onLoad={() => setIsImageLoaded(true)}/>}
            </div>
            <div className="form-user-image-container">
                {imageUrl && <img className="new-user-image" src={imageUrl} alt="preview" onLoad={() => setIsImageLoaded(true)}/>}
            </div>
        </div>
        <form method="post" onSubmit={handleSubmit} encType="multipart/form-data">
            <label className="labels">
                Name
                <input 
                    type="text" 
                    name="name" 
                    placeholder="name"
                    value={name}
                    onChange={e => setName(e.target.value)} />
            </label>
            <label className="labels">
                Surname
                <input 
                    type="text" 
                    name="surname" 
                    placeholder="surname"
                    value={surname}
                    onChange={e => setSurname(e.target.value)} />
            </label>
            <label className="labels">
                Username
                <input 
                    type="text" 
                    name="username" 
                    placeholder="username"
                    value={username}
                    onChange={e => setUsername(e.target.value)} />
            </label>
            <label className="labels">
                Email
                <input
                    type="text" 
                    name="email" 
                    placeholder="email"
                    value={email}
                    onChange={e => {
                        const email = e.target.value;
                        setEmail(email);
                        setEmailValid(isEmailValid(email));
                    } }
                    />
                {formSubmitted && !isEmailValidState && (
                    <p className="validation-message">Please enter a valid email address.</p>
                )}
            </label>
            <label className="labels">
                * Profile Picture 
                    <br/>- Please allow your picture to load
                    <br/>- File size under 1MB
                {isUploading ? (
                    <div className="flex-no-space">  
                    <div className="spinner-form">
                        <input type="file" name="ramen"className="file-upload-button" onChange={uploadImage}/>
                    </div>
                    <div className="spinner-form-container">
                        <img src={Spinner} alt="spinner" className="spinner"/>
                    </div>
                </div>
                ) : (
                    <input type="file" name="user-image" onChange={uploadImage}/>
                )}
            </label>
            <label className="labels">
                * Banner Image 
                    <br/>- Please allow your picture to load
                    <br/>- File size under 1MB
                {isUploadingBanner ? (
                    <div className="flex-no-space">  
                    <div className="spinner-form">
                        <input type="file" name="ramen" onChange={uploadBannerImage}/>
                    </div>
                    <div className="spinner-form-container">
                        <img src={Spinner} alt="spinner" className="spinner"/>
                    </div>
                </div>
                ) : (
                    <input type="file" name="banner-image" onChange={uploadBannerImage}/>
                )}
            </label>
            <label className="labels hidden">
                imageUrl
                <input
                    type="text" 
                    name="imageUrl" 
                    value={imageUrl}
                    onChange={e => setImageUrl(e.target.value)} />
            </label>
            <label className="labels hidden">
                headerImageUrl
                <input
                    type="text" 
                    name="imageUrl" 
                    value={header_image_url}
                    onChange={e => setHeaderImageUrl(e.target.value)} />
            </label>
            <label className="labels hidden">
                publicId
                <input
                    type="text" 
                    name="publicId" 
                    value={publicId}
                    onChange={e => setPublicId(e.target.value)} />
            </label>
            <label className="labels hidden">
                headerPublicId
                <input
                    type="text" 
                    name="publicId" 
                    value={header_public_id}
                    onChange={e => setHeaderPublicId(e.target.value)} />
            </label>
            {isImageLoaded && allFieldsEntered ?(
                        <input
                            type="submit"
                            value="Update"
                            className="primary-submit-button"
                            disabled={!isImageLoaded}
                        />
                    ) : (
                        <input
                            type="submit"
                            value="Fill in form first"
                            className="primary-submit-button-grey"
                            disabled={!isImageLoaded}
                        />
                    )}
                <p>
                    Please note that you will be redirected to login
                    <br/>Use the same details to enter
                </p>
        </form>
    </div>
    )
};

export default UpdateUserForm;
